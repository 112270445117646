import React, { Component } from 'react'
import { connect } from "react-redux";
import { Helmet } from "react-helmet"
import draftToHtml from 'draftjs-to-html'
import InfiniteScroll from "react-infinite-scroll-component"
//import { Spinner } from "reactstrap"
import communityActions from "../../redux/community/actions";

const {
  productUpdateGetAll,
} = communityActions;

//const items_per_page = 2

class CommunityProductUpdates extends Component {
  state = {
    currentPage: 1,
    items: []
  }

  componentDidMount = () => {
    this.props.productUpdateGetAll();
  }

  fetchMoreData = () => {
  }

  render() {
    const { product_updates } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Product Updates | AccelerList</title>
          <meta name="description" content="Get updates and news from AccelerList" />
        </Helmet>
        <InfiniteScroll
          dataLength={product_updates?.items ? product_updates?.items.length : 0}
          //next={this.fetchMoreData}
          // hasMore={this.state.items.length < data.length}
          //loader={
          //<div className="text-center text-muted mt-3">
          //<Spinner size="sm" color="dark"></Spinner> Loading more posts...
          //</div>
          //}
          //endMessage={
          //<p className="text-center text-muted mt-2">No more post.</p>
          //}
        >
          {product_updates?.items && product_updates?.items.map(item => (
            <PostItem
              key={`post-${item.id}`}
              item={item}
            />
          ))}
        </InfiniteScroll>
      </React.Fragment>
    )
  }
}

const PostItem = ({item}) => (
  <div className="post-item mb-5 d-flex gap-3" key={item?.id}>
    <div className="post-item-meta mt-1 text-end">
      <div className="post-item-date mb-2">
        <strong>{item.date}</strong>
      </div>
      <ul className="post-item-tags list-unstyled">
        {item?.tags_dict?.map(tag => (
          <li className="mb-2">
            <div
              className="d-inline-block rounded px-2"
              style={{
                border: '1px solid var(--bs-gray-200)',
                color: 'var(--bs-gray-500)',
              }}
            >
              {tag}
            </div>
          </li>
        ))}
      </ul>
    </div>
    <div className="post-item-main">
      <div className="inner-page-header mb-2">
        <h1 className="inner-page-title">{item?.title}</h1>
      </div>
      <div className="inner-page-content">
        <div className="mb-3">
          <img src={item?.image} alt="" className="rounded img-fluid" style={{ maxHeight: 500 }} />
        </div>
        <div
          dangerouslySetInnerHTML={{__html: draftToHtml(item?.text)}}
        />
      </div>
    </div>
  </div>
)

export default connect(
  state => ({
    product_updates: state.Community.get("product_updates"),
  }),
  {
    productUpdateGetAll,
  }
)(CommunityProductUpdates);
