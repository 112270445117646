import { useRef, useState } from "react"
import { Button } from "reactstrap"
import './style.css'

const VideoPlayer = ({ id, title, objectFit }) => {
  let videoRef = useRef(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  return (
  <div className={`video-player feature-video ${isVideoPlaying ? 'playing' : ''}`}>
    <video
      preload="auto"
      poster={`https://image.mux.com/${id}/thumbnail.jpg?width=320`}
      ref={videoRef}
      style={objectFit && { objectFit: objectFit }}
    >
      <source src={`https://stream.mux.com/${id}/low.mp4`} type="video/mp4" />
    </video>
    <div className="feature-video-overlay">
      <h4 className="feature-video-title">{title}</h4>
    </div>
    <Button
      color="primary"
      onClick={() => {
        if(videoRef.current.paused) {
          videoRef.current.play()
          setIsVideoPlaying(true)
        } else {
          videoRef.current.pause()
          setIsVideoPlaying(false)
        }
      }}
    >
      <span className={`icon icon-${isVideoPlaying ? 'pause' : 'play'} bg-white`} />
    </Button>
  </div>
)}

export default VideoPlayer