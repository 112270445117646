import React, { Component, useState, useRef } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { Link, useParams } from "react-router-dom"
import StatusBadge from "./StatusBadge"
import moment from "moment"
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Pagination, PaginationItem, PaginationLink, Toast, ToastBody, UncontrolledDropdown } from "reactstrap"
import Dialog from "../../../shared/components/Dialog"
import Select from 'react-select'
import { FILE_UPLOAD_LIMIT, FILE_UPLOAD_LIMIT_NUM } from "./constants"
import LoginModal from "../LoginModal"
import communityActions from "../../../redux/community/actions";
import ImageModal from "./ImageModal"
import { protectSpecialUsername, usernameToColor } from '../../../helpers/utils';
import Loading from "../../../shared/components/Loading"

const {
  roadmapGet,
  roadmapUpvote,
  roadmapReply,
  roadmapUploadImage,
  roadmapClearImage,
  adminRoadmapUpdate,
  roadmapGetCategories,
  adminRoadmapDelete,
  adminRoadmapReplyDelete,
} = communityActions;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class RequestDetails extends Component {
  state = {
    deleteRequestId: null,
    selectedCategory: null,
    fileImage: null,
    showLoginModal: false,
    showCategoryDropdown: false,
    showImageModal: false
  }

  componentDidMount = () => {
    this.props.roadmapGetCategories();
    this.props.roadmapGet({
      slug: this.props.params?.slug,
      user: this.props.userData?.id,
    })
  }

  generateCategries = () => {
    const { roadmap_categories } = this.props;
    if(roadmap_categories){
      return roadmap_categories.map(item => {
        return {value: item.id, label: item.name}
      })
    }

    return [];
  }

  render() {
    const { deleteRequestId, showLoginModal, showCategoryDropdown, showImageModal } = this.state
    const {
      userData,
      roadmap,
      roadmap_image,
      roadmap_image_loading,
      roadmap_working
    } = this.props

    return (
      <div className="request-details">
        <Helmet>
          <title>{`${roadmap?.title} | Feature Roadmap | AccelerList`}</title>
          <meta name="description" content={roadmap?.description?.substring(0, 299)} />
        </Helmet>
        <div className="inner-page-header">
          <Link to="/community/feature-roadmap">&larr; Back to requests</Link>
        </div>
        <div className="inner-page-content">
        {roadmap_working ?
          <Loading className="py-4" />
          :
          <React.Fragment>
          <div className="d-flex gap-3 align-items-start mb-3">
            <div
              className="feature-request-vote"
              role="button"
              onClick={() => this.props.roadmapUpvote({id_roadmap: roadmap?.id})}
              style={roadmap.upvoted ? { pointerEvents: "none" } : {}}
            >
              <span className={`icon icon-caret-up ${roadmap.upvoted ? 'bg-danger' : ''}`} />
              <span>{roadmap.upvotes ? roadmap.upvotes : 0}</span>
            </div>
            <div>
              <h1 className="request-details-title">{roadmap?.title}</h1>
              <div className="hstack gap-3">
                {roadmap?.status !== 'FEATURE_REQUEST' && <StatusBadge status={roadmap?.status} />}
                <small><span className="text-muted">Category:</span> {roadmap?.category?.name}</small>
              </div>
            </div>
            {/* ADMIN ONLY */}
            {userData?.is_admin &&
            <div className="hstack gap-3 ms-auto">
              {/* Please keep this UI for changing status be the dropdown toggle and menu
              instead of React Select since it's simpler choices */}
              <UncontrolledDropdown size="sm">
                <DropdownToggle caret color="link">Change status</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this.props.adminRoadmapUpdate({id: roadmap?.id, update_data: {status: "FEATURE_REQUEST"}})}
                  >No Status</DropdownItem>
                  <DropdownItem
                    onClick={() => this.props.adminRoadmapUpdate({id: roadmap?.id, update_data: {status: "UNDER_REVIEW"}})}
                  >Under Review</DropdownItem>
                  <DropdownItem
                    onClick={() => this.props.adminRoadmapUpdate({id: roadmap?.id, update_data: {status: "IN_PROGRESS"}})}
                  >In Progress</DropdownItem>
                  <DropdownItem
                    onClick={() => this.props.adminRoadmapUpdate({id: roadmap?.id, update_data: {status: "COMPLETE"}})}
                  >Complete</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Dropdown isOpen={showCategoryDropdown} toggle={() => this.setState({ showCategoryDropdown: !showCategoryDropdown })} size="sm">
                <DropdownToggle caret color="link">Move to category</DropdownToggle>
                <DropdownMenu end className="p-0 border-0" style={{ minWidth: '12rem' }}>
                  <Select
                      options={this.generateCategries()}
                      value={this.state.selectedCategory}
                      onChange={selected => {
                        this.setState({
                          showCategoryDropdown: false
                        })
                        this.props.adminRoadmapUpdate({id: roadmap?.id, update_data: {id_category: selected.value}});
                      }}
                    />
                  {/* <DropdownItem toggle={false}>

                  </DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
              <span
                className="icon icon-trash bg-danger"
                role="button"
                onClick={() => this.setState({ deleteRequestId: roadmap?.id })}
              />
            </div>
            }
          </div>
          <div>
            <div className="hstack gap-3">
              <div
                className="post-info-avatar"
                style={roadmap?.author?.profile_image && { backgroundImage: `url(${roadmap?.author?.profile_image})`}}
              >
                {roadmap?.author?.profile_image ?
                <img src={roadmap?.author?.profile_image} alt="" />
                :
                <span
                  className={`avatar-default`}
                  style={usernameToColor(roadmap?.author?.username)}
                >{roadmap?.author?.username.charAt(0)}</span>
                }
              </div>
              <div className="d-flex gap-2">
                <strong>{protectSpecialUsername(roadmap?.author?.username)}</strong>
                <span className="post-info-timestamp">{moment(roadmap?.created_at).from(new Date())}</span>
              </div>
            </div>
            <div className="request-details-main">
              <div className="request-details-description">
                {roadmap?.description}
              </div>
              {roadmap?.image_url &&
                <div
                  className="border rounded my-2"
                  role="button"
                  style={{ maxWidth: '20rem'}}
                  onClick={() => this.setState({ showImageModal: true })}
                >
                  <img src={roadmap?.image_url} alt="" className="img-fluid rounded" />
                  <ImageModal
                    isOpen={showImageModal}
                    toggle={() => this.setState({ showImageModal: !showImageModal })}
                    img_url={roadmap?.image_url}
                  />
                </div>
              }

              {/* LEAVE COMMENT */}
              <div className="my-3">
                {userData.username ?
                <ReplyForm
                  roadmap_image={roadmap_image}
                  roadmap_image_loading={roadmap_image_loading}
                  roadmapUploadImage={this.props.roadmapUploadImage}
                  roadmapReply={this.props.roadmapReply}
                  roadmap={roadmap}
                  roadmapClearImage={this.props.roadmapClearImage}
                />
                :
                <Button color="link" onClick={() => this.setState({ showLoginModal: true })}>Please login to comment</Button>
                }
              </div>

              <hr className="my-4" />

              {/* REPLIES/COMMENTS */}
              <div className="request-details-replies">
                <h2 className="request-details-title mb-3">Comments</h2>
                {roadmap?.replies?.length > 0 ?
                <React.Fragment>
                <ul className="replies-list">
                  {roadmap?.replies?.map(item => (
                    <ReplyItem
                      key={`reply-item-comment-${item.id}`}
                      id={item.id}
                      profile_image={item?.author?.profile_image}
                      username={item?.author?.username ? item?.author?.username : `user${item?.author?.id}`}
                      real_username={item?.author?.username}
                      created_at={item?.created_at}
                      description={item?.reply}
                      userData={userData}
                      showLoginModal={() => this.setState({ showLoginModal: true })}
                      replies={item?.replies}
                      image_url={item?.image_url}
                      roadmap_image={roadmap_image}
                      roadmap_image_loading={roadmap_image_loading}
                      roadmapUploadImage={this.props.roadmapUploadImage}
                      roadmapReply={this.props.roadmapReply}
                      roadmap={roadmap}
                      roadmapClearImage={this.props.roadmapClearImage}
                      adminRoadmapReplyDelete={this.props.adminRoadmapReplyDelete}
                    />
                  ))}
                </ul>
                {/* Per Page size: 10 items (Level 1 reply) */}
                <Pagination size="sm" className="mt-3" listClassName="justify-content-center">
                  <PaginationItem disabled>
                    <PaginationLink>Newer</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink>Older</PaginationLink>
                  </PaginationItem>
                </Pagination>
                </React.Fragment>
                :
                <p className="text-muted">Be the first to comment on this feature request.</p>
                }
              </div>
            </div>
          </div>
          </React.Fragment>
        }
        </div>
        {deleteRequestId &&
        <Dialog
          isOpen={true}
          close={() => this.setState({ deleteRequestId: null })}
          type="delete"
          title="Delete request"
          message={<span>Feature request and all replies will be deleted.
          <br/>Are you sure want to delete this request?</span>}
          deleteButtonText="Delete request"
          onDelete={() => {
            this.props.adminRoadmapDelete({
              id: deleteRequestId,
              redirect: true,
            });
            this.setState({ deleteRequestId: null })
          }}
        />
        }
        <LoginModal
          isOpen={showLoginModal}
          toggle={() => this.setState({ showLoginModal: !showLoginModal})}
        />
      </div>
    )
  }
}

const ReplyItem = ({
  id, profile_image, username, real_username, created_at, description,
  userData, showLoginModal, replies,
  roadmap_image, roadmap_image_loading, roadmapUploadImage,
  roadmapReply, roadmap, roadmapClearImage, image_url, adminRoadmapReplyDelete,
  childReply = false}) => {
  const [showReplyFormId, setShowReplyFormId] = useState(null)
  const [deleteReplyId, setDeleteReplyId] = useState(null)
  const [showReplyImageModal, setShowReplyImageModal] = useState(false)

  return (
    <li className="replies-list-item" key={`reply-item-${id}`}>
      <div className="hstack gap-2">
        <div
          className="post-info-avatar"
          style={profile_image && { backgroundImage: `url(${profile_image})`}}
        >
          {profile_image ?
          <img src={profile_image} alt="" />
          :
          <span
            className={`avatar-default`}
            style={usernameToColor(username)}
          >{username.charAt(0)}</span>
          }
        </div>
        <div className="d-flex gap-2">
          <strong>{protectSpecialUsername(username)}</strong>
          <span className="post-info-timestamp">{moment(created_at).from(new Date())}</span>
        </div>
      </div>
      <div className="replies-main">
        <div className="replies-description">
          {description}
        </div>
        {image_url &&
        <div
          className="border rounded my-2"
          role="button"
          style={{ maxWidth: '5rem'}}
          onClick={() => setShowReplyImageModal(true)}
        >
          <img src={image_url} alt="" className="img-fluid rounded" />
          <ImageModal
            isOpen={showReplyImageModal}
            toggle={() => setShowReplyImageModal((prevState) => !prevState)}
            img_url={image_url}
          />
        </div>
        }
        <div className="d-flex gap-2">
          {/* I think we limit for now only 2 levels comments */}
          {!childReply &&
          <Button
            color="link"
            size="sm"
            onClick={() => {
              if(userData.username) setShowReplyFormId(id)
              else showLoginModal()
            }}
          >Reply</Button>
          }
          {userData.is_admin &&
          <Button
            color="link"
            size="sm"
            className="text-danger"
            onClick={() => setDeleteReplyId(id)}
          >Delete</Button>
          }
        </div>
        {showReplyFormId === id &&
        <ReplyForm
          placeholder="Write your reply"
          roadmap_image={roadmap_image}
          roadmap_image_loading={roadmap_image_loading}
          roadmapUploadImage={roadmapUploadImage}
          roadmapReply={roadmapReply}
          roadmap={roadmap}
          roadmapClearImage={roadmapClearImage}
          id_parent={id}
          closeReplyForm={() => setShowReplyFormId(null)}
        />
        }
        {deleteReplyId &&
        <Dialog
          isOpen={true}
          close={() => setDeleteReplyId(null)}
          type="delete"
          title="Delete reply"
          message="Are you sure want to delete this reply?"
          deleteButtonText="Delete reply"
          onDelete={() => {
            adminRoadmapReplyDelete({id: deleteReplyId})
            setDeleteReplyId(null)
          }}
        />
        }
      </div>
      {replies?.length > 0 &&
      <ul className="replies-list">
        {replies.map(r => (
        <ReplyItem
          key={`reply-item-${r.id}`}
          id={r.id}
          profile_image={r?.author?.profile_image}
          username={r?.author?.username ? r?.author?.username : `user${r?.author?.id}`}
          real_username={r?.author?.username}
          created_at={r?.created_at}
          description={r?.reply}
          userData={userData}
          showLoginModal={showLoginModal}
          childReply={true}
          image_url={r?.image_url}
          roadmap_image={roadmap_image}
          roadmap_image_loading={roadmap_image_loading}
          roadmapUploadImage={roadmapUploadImage}
          roadmapReply={roadmapReply}
          roadmap={roadmap}
          roadmapClearImage={roadmapClearImage}
          adminRoadmapReplyDelete={adminRoadmapReplyDelete}
        />
        ))}
      </ul>
      }
    </li>
  )
}

const ReplyForm = ({
    placeholder,
    roadmap_image,
    roadmapUploadImage,
    roadmap_image_loading,
    roadmapReply,
    roadmap,
    roadmapClearImage,
    id_parent,
    closeReplyForm,
}) => {
  let uploadEl = useRef(null)
  const [fileSizeError, setFileSizeError] = useState(false)
  const [reply, setReply] = useState("")

  const onImageUpload = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const uploadedFiles = e.target.files;

    let timer = null
    if(uploadedFiles[0]) {
      if(uploadedFiles[0].size > FILE_UPLOAD_LIMIT) {
        setFileSizeError(true)
        timer = setTimeout(() => {
          setFileSizeError(false)
          clearTimeout(timer)
        }, 4000)
      } else {
        // upload image and set the URL for preview
        var formData = new FormData();
        formData.append(
          "file",
          uploadedFiles[0]
        );
        roadmapUploadImage(formData);
      }
    }
  }

  return (
    <div className="mt-3">
      <Input
        type="textarea"
        placeholder={placeholder ? placeholder : 'Leave a comment'}
        rows="3"
        autoFocus
        value={reply}
        onChange={(e) => setReply(e.target.value)}
      />
      {roadmap_image &&
      <div className="request-uploaded-image mt-2">
        <img src={roadmap_image} alt="" width={100} />
        <span
          className="request-uploaded-image-delete"
          role="button"
          onClick={() => console.log('delete this image')}
        >&#x2715;</span>
      </div>
      }
      <div className="hstack mt-2">
        <div>
          {!roadmap_image &&
          <React.Fragment>
          <span
            className="icon icon-18 icon-image d-block me-3"
            role="button"
            onClick={() => { uploadEl.click() }}
          />
          <input
            type="file"
            id="imageFile"
            className="d-none"
            ref={(ref) => uploadEl = ref}
            accept={"image/png, image/jpeg"}
            onChange={onImageUpload}
          />
          </React.Fragment>
          }
        </div>
        <Button
          color="primary"
          size="sm"
          disabled={roadmap_image_loading || !reply}
          onClick={() => {
            roadmapReply({
              id_roadmap: roadmap?.id,
              reply: reply,
              image_url: roadmap_image,
              id_parent: id_parent,
            })
            setReply("");
            roadmapClearImage();
            if(id_parent){ closeReplyForm(); }
          }}
        >Submit</Button>
      </div>
      {fileSizeError &&
      <div className="position-fixed bottom-0 start-50 p-3 translate-middle-x" style={{ zIndex: 11 }}>
        <Toast isOpen={fileSizeError}>
          <ToastBody className="text-danger">Please upload image with size less than {FILE_UPLOAD_LIMIT_NUM} MB</ToastBody>
        </Toast>
      </div>
      }
    </div>
  )
}

export default withParams(connect(
  state => ({
    userData: state.User.get("userData"),
    roadmap: state.Community.get("roadmap"),
    roadmap_working: state.Community.get("roadmap_working"),
    roadmap_image: state.Community.get("roadmap_image"),
    roadmap_image_loading: state.Community.get("roadmap_image_loading"),
    roadmap_categories: state.Community.get("roadmap_categories"),
  }),
  {
    roadmapGet,
    roadmapUpvote,
    roadmapReply,
    roadmapUploadImage,
    roadmapClearImage,
    adminRoadmapUpdate,
    roadmapGetCategories,
    adminRoadmapDelete,
    adminRoadmapReplyDelete,
  }
)(RequestDetails));
