import React, { Component } from "react";
import { logoSm, ebayCompatible, footerChart } from "../../../assets/images";
import './style.css';
import moment from 'moment';
import { Container } from "reactstrap";

class Footer extends Component {
  render() {
    return(
      <footer className="page-footer">
        <section className="section-footer-cta">
          <div className="footer-chart">
            <img src={footerChart} alt="" className="img-fluid" />
          </div>
        </section>
        <Container>
          <div className="inner">
            <div className="footer-col">
              <img src={logoSm} alt="Logo" className="mb-2" />
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/groups/accelerlist/"
                  target="_blank"
                  rel="noopener noreferrer"
                ><span className="icon icon-facebook icon-24 bg-white" /></a>
                <a
                  href="https://www.youtube.com/channel/UCbWDBnhZ21OHla7ESB9SYwQ"
                  target="_blank"
                  rel="noopener noreferrer"
                ><span className="icon icon-youtube icon-24 bg-white" /></a>
              </div>
              <img src={ebayCompatible} className="img-fluid d-block mb-3" alt="eBay Compatible Application" width="160" />
              <div id="entrust-net-seal"><a href="https://www.entrust.com/ssl-certificates/" target="_blank" rel="noopener noreferrer">SSL Certificate</a></div>
            </div>
            <div className="footer-col">
              <h5 className="footer-heading">Learn More</h5>
              <ul className="list-unstyled">
                <li><a href="https://blog.accelerlist.com/inventory-lab/" target="_blank" rel="noopener noreferrer">AccelerList vs Inventory Lab</a></li>
                <li><a href="https://blog.accelerlist.com/accelerlist-vs-scanlister/" target="_blank" rel="noopener noreferrer">AccelerList vs ScanLister</a></li>
                <li><a href="https://blog.accelerlist.com/accelerlist-vs-joelister/" target="_blank" rel="noopener noreferrer">AccelerList vs Joelister</a></li>
                <li><span>AccelerList vs TurboLister</span></li>
                <li><span>ProfitReprice™ vs RepriceIt</span></li>
                <li><a href="/accelerlist_alternatives">AccelerList Alternatives</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h5 className="footer-heading">Use Cases</h5>
              <ul className="list-unstyled">
                <li><span>Selling Books on Amazon</span></li>
                <li><span>Selling CDs on Amazon</span></li>
                <li><span>Selling DVDs on Amazon</span></li>
                <li><span>Selling Toys on Amazon</span></li>
              </ul>
            </div>
            <div className="footer-col">
              <h5 className="footer-heading">Help &amp; Support</h5>
              <div className="d-flex">
                <ul className="list-unstyled list-2-col">
                  <li><a href="/terms_conditions">Terms &amp; Conditions</a></li>
                  <li><a href="https://accelerlist.helpjuice.com/" target="_blank" rel="noopener noreferrer">Knowledge Base</a></li>
                  <li><a href="https://accelerlist.upvoty.com" target="_blank" rel="noopener noreferrer">Request Feature</a></li>
                  <li><a href="/faq_ebay">eBay FAQ</a></li>
                  <li><a href="/privacy_policy">Privacy Policy</a></li>
                  <li><a href="/press">Press</a></li>
                  <li><a href="/accelerprint">AccelerPrint</a></li>
                  <li><a href="/printing_drivers">Printing Drivers</a></li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
        <div className="trademarks">
          <Container>
            <p className="text-center">Registered Trademarks</p>
            <ul>
              <li>Refund Finder™ - 90287945</li>
              <li>AccelerList™ - 88384600</li>
              <li>Profit Reprice™ - 90252754</li>
              <li>Profit Hunt™ - 90252311</li>
              <li>Faster Workflows™ - 90288116</li>
              <li>Garage Money™ - 90426468</li>
              <li>Freedom Life™ - 90426380</li>
            </ul>
          </Container>
        </div>
        <p className="copyright">Copyright {moment().format('YYYY')} AccelerList</p>
      </footer>
    )
  }
}

export default Footer;
