import { all, fork, takeLatest, put } from "redux-saga/effects";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* communityGetCategories() {
  yield commonSaga(
    actions.COMMUNITY_GET_CATEGORIES,
    apiCalls.get,
    apiEndpoints.question.category_all,
    {
      ...default_config,
      ...{
        success_show: false
      }
    }
  );
}

export function* communityCreateQuestion() {
  yield commonSaga(
    actions.COMMUNITY_CREATE_QUESTION,
    apiCalls.post,
    apiEndpoints.question.question,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export function* communityGetAllQuestions() {
  yield commonSaga(
    actions.COMMUNITY_GET_ALL_QUESTIONS,
    apiCalls.get,
    apiEndpoints.question.all,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* communityGetQuestion() {
  yield commonSaga(
    actions.COMMUNITY_GET_QUESTION,
    apiCalls.get,
    apiEndpoints.question.question,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* communityQuestionUpvote() {
  yield commonSaga(
    actions.COMMUNITY_QUESTION_UPVOTE,
    apiCalls.post,
    apiEndpoints.question.upvote,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* communityQuestionReply() {
  yield commonSaga(
    actions.COMMUNITY_QUESTION_REPLY,
    apiCalls.post,
    apiEndpoints.question.reply,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* communityQuestionPin() {
  yield commonSaga(
    actions.COMMUNITY_QUESTION_PIN,
    apiCalls.post,
    apiEndpoints.question.pin,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* communityQuestionMoveTopic() {
  yield commonSaga(
    actions.COMMUNITY_QUESTION_MOVE_TOPIC,
    apiCalls.post,
    apiEndpoints.question.move_topic,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminUpdateQuestion() {
  yield commonSaga(
    actions.ADMIN_UPDATE_QUESTION,
    apiCalls.put,
    apiEndpoints.admin.question,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminUpdateReply() {
  yield commonSaga(
    actions.ADMIN_UPDATE_REPLY,
    apiCalls.put,
    apiEndpoints.admin.reply,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminDeleteReply() {
  yield commonSaga(
    actions.ADMIN_DELETE_REPLY,
    apiCalls.delete,
    apiEndpoints.admin.reply,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminDeleteQuestion() {
  yield commonSaga(
    actions.ADMIN_DELETE_QUESTION,
    apiCalls.delete,
    apiEndpoints.admin.question,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapGetCategories() {
  yield commonSaga(
    actions.ROADMAP_GET_CATEGORIES,
    apiCalls.get,
    apiEndpoints.roadmap.categories,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapCreate() {
  yield commonSaga(
    actions.ROADMAP_CREATE,
    apiCalls.post,
    apiEndpoints.roadmap.roadmap,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    },
  );
}

export function* roadmapSearch() {
  yield commonSaga(
    actions.ROADMAP_SEARCH,
    apiCalls.get,
    apiEndpoints.roadmap.search,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapUploadImage() {
  yield commonSaga(
    actions.ROADMAP_UPLOAD_IMAGE,
    apiCalls.post,
    apiEndpoints.roadmap.image_upload,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapGet() {
  yield commonSaga(
    actions.ROADMAP_GET,
    apiCalls.get,
    apiEndpoints.roadmap.roadmap,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapClearImage() {
  yield takeLatest(actions.ROADMAP_CLEAR_IMAGE, function*(payload) {
    yield put({type: `${actions.ROADMAP_CLEAR_IMAGE}_SUCCESS`});
  })
}

export function* roadmapLoadImage() {
  yield takeLatest(actions.ROADMAP_LOAD_IMAGE, function*(payload) {
    yield put({
      type: `${actions.ROADMAP_LOAD_IMAGE}_SUCCESS`,
      data: payload?.data,
    });
  })
}

export function* roadmapUpvote() {
  yield commonSaga(
    actions.ROADMAP_UPVOTE,
    apiCalls.post,
    apiEndpoints.roadmap.upvote,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapReply() {
  yield commonSaga(
    actions.ROADMAP_REPLY,
    apiCalls.post,
    apiEndpoints.roadmap.reply,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminRoadmapDelete() {
  yield commonSaga(
    actions.ADMIN_ROADMAP_DELETE,
    apiCalls.delete,
    apiEndpoints.admin.roadmap,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminRoadmapUpdate() {
  yield commonSaga(
    actions.ADMIN_ROADMAP_UPDATE,
    apiCalls.put,
    apiEndpoints.admin.roadmap,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* adminRoadmapReplyDelete() {
  yield commonSaga(
    actions.ADMIN_ROADMAP_REPLY_DELETE,
    apiCalls.delete,
    apiEndpoints.admin.roadmap_reply,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* roadmapGetRoadmaps() {
  yield commonSaga(
    actions.ROADMAP_GET_ROADMAPS,
    apiCalls.get,
    apiEndpoints.roadmap.roadmaps,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* productUpdateGetAll() {
  yield commonSaga(
    actions.PRODUCT_UPDATE_GET_ALL,
    apiCalls.get,
    apiEndpoints.product.all,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export default function* userSaga() {
	yield all([
    fork(communityGetCategories),
    fork(communityCreateQuestion),
    fork(communityGetAllQuestions),
    fork(communityGetQuestion),
    fork(communityQuestionUpvote),
    fork(communityQuestionReply),
    fork(communityQuestionPin),
    fork(communityQuestionMoveTopic),
    fork(adminUpdateQuestion),
    fork(adminUpdateReply),
    fork(adminDeleteReply),
    fork(adminDeleteQuestion),
    fork(roadmapGetCategories),
    fork(roadmapCreate),
    fork(roadmapSearch),
    fork(roadmapUploadImage),
    fork(roadmapGet),
    fork(roadmapClearImage),
    fork(roadmapUpvote),
    fork(roadmapReply),
    fork(adminRoadmapDelete),
    fork(adminRoadmapUpdate),
    fork(adminRoadmapReplyDelete),
    fork(roadmapGetRoadmaps),
    fork(productUpdateGetAll),
    fork(roadmapLoadImage),
	]);
}
