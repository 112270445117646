import React, { Component } from 'react';
import { connect } from 'react-redux';
import WistiaPlayer from "../../shared/components/WistiaPlayer";
import { Col, Container, Nav, NavItem, Row } from "reactstrap";
import { logoEbay } from "../../assets/images";
import { digitСonversion } from "../../helpers/utils";
import './style.css';
import ChartEbay from "./ChartEbay";
import { Link as LocalLink, scroller } from 'react-scroll';
import faq_data from "./faq_data";
import CardFAQ from "./CardFAQ";
import frontActions from "../../redux/front/actions"

const {
  fetchEbaySalesGraph,
} = frontActions;

class FaqEbay extends Component {
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 300,
      delay: 0,
    })
  }

  componentDidMount = () => {
    this.props.fetchEbaySalesGraph();
  }

  render() {

    return (
      <div className="inner-page page-faq">
        <div className="inner-page-header">
          <Container>
            <h1 className="inner-page-title">eBay FAQ</h1>
            <p className="inner-page-subtitle mb-0">Can't find the answers you're looking for? Here are some of our most Frequently Asked Questions</p>
            <p>Please check our <a href="https://accelerlist.helpjuice.com/" target="_blank" rel="noreferrer noopener">Knowledge Base</a> for detailed help center.</p>
          </Container>
        </div>
        <div className="inner-page-content">
          <Container>
            <section>
              <Row className="align-items-center">
                <Col lg={6}>
                <div className="ebay-counter">
                  <h3 className="ebay-counter-text">
                    <span className="ebay-counter-number">{digitСonversion(this.props.ebay_count.count, "decimal", null, 0)}</span>
                    &nbsp;products listed on <span className="d-none">eBay</span> <img src={logoEbay} alt="eBay Logo" height={30} />
                  </h3>
                  <h3 className="ebay-counter-text">
                    <span className="ebay-counter-number">{digitСonversion(this.props.ebay_count.sales, "currency", "USD", 2)}</span>
                    &nbsp;in sales generated for our sellers
                  </h3>
                </div>
                </Col>
                <Col lg={6}>
                  <WistiaPlayer
                    hashedId="fmh3es7owu"
                    playerColor="#00943E"
                    aspectRatio={38}
                  />
                </Col>
              </Row>
            </section>
            <section>
              {this.props.ebay_sales_graph_data &&
              <ChartEbay
                data={this.props.ebay_sales_graph_data}
              />
              }
            </section>
            <section>
              <Row>
                <Col lg={3} className="faq-nav-wrapper">
                  <div className="faq-nav">
                    <Nav vertical>
                    {faq_data && faq_data.topics && faq_data.topics.map((item, idx) => {
                      return (
                        <NavItem key={`faq-nav-${idx}`}>
                          <LocalLink
                            href={`#s${item.id}`}
                            className="nav-link"
                            to={`s${item.id}`}
                            spy={true}
                            offset={-60}
                          >{item.name}</LocalLink>
                        </NavItem>
                      )
                    })}
                    </Nav>
                  </div>
                </Col>
                <Col lg={9}>
                  {faq_data && faq_data.topics && faq_data.topics.map((item, idx) => {
                    const articles = faq_data.articles.filter(article => article.topic_id === item.id);
                    return (
                      <div
                        key={`faq-article-${idx}`}
                        id={`s${item.id}`}
                      >
                        <CardFAQ
                          title={item.name}
                          data={articles}
                        />
                      </div>
                    )
                  })}
                  <p className="mt-5 lead text-center">Didn't find what you were looking for? Chat with us by clicking icon at the bottom right of your screen.</p>
                </Col>
              </Row>
            </section>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    ebay_count: state.Front.get("ebay_count"),
    ebay_sales_graph_data: state.Front.get("ebay_sales_graph_data"),
  }),
  {
    fetchEbaySalesGraph,
  }
)(FaqEbay);
