import { Map } from "immutable";

const initState = new Map({
  categories: {},
  categories_working: false,
  questions: [],
  questions_working: false,
  question: {},
  roadmap_categories: [],
  roadmap_working: false,
  roadmaps: {},
  roadmap_image: null,
  roadmap_image_loading: false,
  roadmap: {},
  feature_roadmap: {},
  feature_roadmap_loading: false,
  product_updates: {},
  product_updates_working: false,
});

export default function communityReducer(state = initState, action) {
  switch (action.type) {

    case "PRODUCT_UPDATE_GET_ALL":
      return state
        .set("product_updates_working", true)
    case "PRODUCT_UPDATE_GET_ALL_SUCCESS":
      return state
        .set("product_updates", {...action.data.data})
        .set("product_updates_working", false)
    case "PRODUCT_UPDATE_GET_ALL_FAILED":
      return state
        .set("product_updates_working", false)

    case "ROADMAP_GET_ROADMAPS":
      return state
        .set("feature_roadmap_loading", true)
    case "ROADMAP_GET_ROADMAPS_SUCCESS":
      return state
        .set("feature_roadmap", {...action.data.data})
        .set("feature_roadmap_loading", false)
    case "ROADMAP_GET_ROADMAPS_FAILED":
      return state
        .set("feature_roadmap_loading", false)

    case "ADMIN_ROADMAP_REPLY_DELETE":
      return state
        .set("roadmap_working", true)
    case "ADMIN_ROADMAP_REPLY_DELETE_SUCCESS":
      var admin_roadmap_replay_delete = state.get("roadmap");
      var rd_data = [];
      admin_roadmap_replay_delete?.replies?.forEach(item => {
        if(item.id !== action?.data?.data?.id){
          item.replies = item?.replies?.filter(reply => reply.id !== action?.data?.data?.id);
          rd_data.push(item)
        }
      });
      admin_roadmap_replay_delete.replies = [...rd_data];
      return state
        .set("roadmap", {...admin_roadmap_replay_delete})
        .set("roadmap_working", false)
    case "ADMIN_ROADMAP_REPLY_DELETE_FAILED":
      return state
        .set("roadmap_working", false)

    case "ADMIN_ROADMAP_UPDATE":
      return state
        .set("roadmap_working", true)
    case "ADMIN_ROADMAP_UPDATE_SUCCESS":
      return state
        .set("roadmap", {...state.get("roadmap"), ...action.data.data})
        .set("roadmap_working", false)
    case "ADMIN_ROADMAP_UPDATE_FAILED":
      return state
        .set("roadmap_working", false)

    case "ADMIN_ROADMAP_DELETE":
      return state
        .set("roadmap_working", true)
    case "ADMIN_ROADMAP_DELETE_SUCCESS":
      var admin_roadmap_delete = state.get("roadmaps")
      admin_roadmap_delete.items = admin_roadmap_delete?.items.filter(item => item.id !== action?.data?.data?.id)
      return state
        .set("roadmaps", {...admin_roadmap_delete})
        .set("roadmap_working", false)
    case "ADMIN_ROADMAP_DELETE_FAILED":
      return state
        .set("roadmap_working", false)

    case "ROADMAP_REPLY":
      return state
        .set("roadmap_working", true)
    case "ROADMAP_REPLY_SUCCESS":
      var roadmap_reply = state.get("roadmap");
      if(roadmap_reply?.id === action.data?.data?.id_roadmap){
        roadmap_reply.replies_count = roadmap_reply.replies_count ? roadmap_reply.replies_count + 1 : 1;
      }
      if(action?.data?.data?.id_parent){
        roadmap_reply.replies = roadmap_reply?.replies?.map(item => {
          if(item.id === action?.data?.data?.id_parent){
            if(item.replies){
              item.replies.unshift(action?.data?.data)
            } else {
              item.replies = [action?.data?.data]
            }
          }
          return item;
        })
      } else {
        roadmap_reply?.replies.unshift(action?.data?.data);
      }
      return state
        .set("roadmap", {...roadmap_reply})
        .set("roadmap_working", false)
    case "ROADMAP_REPLY_FAILED":
      return state
        .set("roadmap_working", false)

    case "ROADMAP_UPVOTE":
      return state
        .set("roadmap_working", true)
    case "ROADMAP_UPVOTE_SUCCESS":
      var roadmap_upvote = state.get("roadmap");
      if(roadmap_upvote?.id === action.data?.data?.id_roadmap){
        roadmap_upvote.upvotes = roadmap_upvote.upvotes ? roadmap_upvote.upvotes + 1 : 1;
        roadmap_upvote.upvoted = true;
      }
      return state
        .set("roadmap", {...roadmap_upvote})
        .set("roadmap_working", false)
    case "ROADMAP_UPVOTE_FAILED":
      return state
        .set("roadmap_working", false)

    case "ROADMAP_CLEAR_IMAGE_SUCCESS":
      return state
        .set("roadmap_image", null)

    case "ROADMAP_LOAD_IMAGE_SUCCESS":
      return state
        .set("roadmap_image", action.data.url)

    case "ROADMAP_GET":
      return state
        .set("roadmap", {})
        .set("roadmap_working", true)
    case "ROADMAP_GET_SUCCESS":
      return state
        .set("roadmap", {...action.data.data})
        .set("roadmap_working", false)
    case "ROADMAP_GET_FAILED":
      return state
        .set("roadmap", {})
        .set("roadmap_working", false)

    case "ROADMAP_UPLOAD_IMAGE":
      return state
        .set("roadmap_image", null)
        .set("roadmap_image_loading", true)
    case "ROADMAP_UPLOAD_IMAGE_SUCCESS":
      return state
        .set("roadmap_image", action.data.data.url)
        .set("roadmap_image_loading", false)
    case "ROADMAP_UPLOAD_IMAGE_FAILED":
      return state
        .set("roadmap_image", null)
        .set("roadmap_image_loading", false)

    case "ROADMAP_SEARCH":
      return state
        .set("roadmap_working", true)
    case "ROADMAP_SEARCH_SUCCESS":
      return state
        .set("roadmaps", {...action.data.data})
        .set("roadmap_working", false)
    case "ROADMAP_SEARCH_FAILED":
      return state
        .set("roadmap_working", false)

    case "ROADMAP_CREATE":
      return state
        .set("roadmaps_loading", true)
    case "ROADMAP_CREATE_SUCCESS":
      var roadmap_create_roadmaps = state.get("roadmaps")
      if(roadmap_create_roadmaps.items){
        roadmap_create_roadmaps.items.unshift({...action.data.data})
      } else {
        roadmap_create_roadmaps.items = [{...action.data.data}];
      }
      return state
        .set("roadmaps", {...roadmap_create_roadmaps})
        .set("roadmaps_loading", false)
    case "ROADMAP_CREATE_FAILED":
      return state
        .set("roadmaps_loading", false)

    case "ROADMAP_GET_CATEGORIES":
      return state
        .set("roadmap_working", true)
    case "ROADMAP_GET_CATEGORIES_SUCCESS":
      return state
        .set("roadmap_categories", [...action.data.data])
        .set("roadmap_working", false)
    case "ROADMAP_GET_CATEGORIES_FAILED":
      return state
        .set("roadmap_working", false)

    case "ADMIN_DELETE_QUESTION":
      return state
        .set("questions_working", true)
    case "ADMIN_DELETE_QUESTION_SUCCESS":
      var questions_delete = state.get("questions");
      delete questions_delete[action.data?.data?.id];
      return state
        .set("question", {...questions_delete})
        .set("questions_working", false)
    case "ADMIN_DELETE_QUESTION_FAILED":
      return state
        .set("questions_working", false)

    case "ADMIN_DELETE_REPLY":
      return state
        .set("questions_working", true)
    case "ADMIN_DELETE_REPLY_SUCCESS":
      var question_delete_reply = state.get("question");
      question_delete_reply.replies = question_delete_reply.replies?.filter(item => item.id !== action.data?.data?.id)
      return state
        .set("question", {...question_delete_reply})
        .set("questions_working", false)
    case "ADMIN_DELETE_REPLY_FAILED":
      return state
        .set("questions_working", false)

    case "ADMIN_UPDATE_REPLY":
      return state
        .set("questions_working", true)
    case "ADMIN_UPDATE_REPLY_SUCCESS":
      var question_update_reply = state.get("question");
      question_update_reply.replies = question_update_reply?.replies?.map(item => {
        if(item.id === action.data?.data?.id){
          return {...item, ...action.data?.data}
        } else {
          if(action.data?.data?.solution_updated){
            item.is_solution = false;
          }
        }
        return item;
      })
      return state
        .set("question", {...question_update_reply})
        .set("questions_working", false)
    case "ADMIN_UPDATE_REPLY_FAILED":
      return state
        .set("questions_working", false)

    case "ADMIN_UPDATE_QUESTION":
      return state
        .set("questions_working", true)
    case "ADMIN_UPDATE_QUESTION_SUCCESS":
      var questions_update = state.get("questions");
      questions_update.items = questions_update?.items?.map(question => {
        if(question.id === action.data?.data?.id){
          return {...question, ...action.data?.data}
        }
        return question;
      })
      return state
        .set("questions", {...questions_update})
        .set("question", {...state.get("question"), ...action.data?.data})
        .set("questions_working", false)
    case "ADMIN_UPDATE_QUESTION_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_QUESTION_MOVE_TOPIC":
      return state
        .set("questions_working", true)
    case "COMMUNITY_QUESTION_MOVE_TOPIC_SUCCESS":
      var questions_move = state.get("questions");
      if(action.data?.data?.id_topic !== action.data?.data?.old_topic_id){
        questions_move.items = questions_move.items.filter(item => item.id !== action.data?.data?.id)
      }
      return state
        .set("question", {...questions_move})
        .set("questions_working", false)
    case "COMMUNITY_QUESTION_MOVE_TOPIC_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_QUESTION_PIN":
      return state
        .set("questions_working", true)
    case "COMMUNITY_QUESTION_PIN_SUCCESS":
      var questions_pinned = state.get("questions");
      questions_pinned.items = questions_pinned.items.map(item => {
        if(item.id === action.data?.data?.id_question){
          item.pinned = item?.pinned ? false : true;
        }
        return item;
      })
      return state
        .set("question", {...questions_pinned})
        .set("questions_working", false)
    case "COMMUNITY_QUESTION_PIN_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_QUESTION_REPLY":
      return state
        .set("questions_working", true)
    case "COMMUNITY_QUESTION_REPLY_SUCCESS":
      var question_reply = state.get("question");
      if(question_reply?.id === action.data?.data?.id_question){
        if(question_reply.replies){
          question_reply.replies.unshift(action.data?.data);
        } else {
          question_reply.replies = [action.data?.data];
        }
        question_reply.replies_count = question_reply.replies_count ? question_reply.replies_count + 1 : 1;
      }
      return state
        .set("question", {...question_reply})
        .set("questions_working", false)
    case "COMMUNITY_QUESTION_REPLY_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_QUESTION_UPVOTE":
      return state
        .set("questions_working", true)
    case "COMMUNITY_QUESTION_UPVOTE_SUCCESS":
      var questions_upvote = state.get("questions");
      questions_upvote.items = questions_upvote.items.map(item => {
        if(item.id === action.data?.data?.id_question){
          item.upvotes = item.upvotes ? item.upvotes + 1 : 1;
          item.upvoted = true;
        }
        return item;
      })
      var question_upvote = state.get("question");
      if(question_upvote?.id === action.data?.data?.id_question){
        question_upvote.upvotes = question_upvote.upvotes ? question_upvote.upvotes + 1 : 1;
        question_upvote.upvoted = true;
      }
      return state
        .set("questions", {...questions_upvote})
        .set("question", {...question_upvote})
        .set("questions_working", false)
    case "COMMUNITY_QUESTION_UPVOTE_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_GET_QUESTION":
      return state
        .set("question", {})
        .set("questions_working", true)
    case "COMMUNITY_GET_QUESTION_SUCCESS":
      return state
        .set("question", {...action.data.data})
        .set("questions_working", false)
    case "COMMUNITY_GET_QUESTION_FAILED":
      return state
        .set("question", {})
        .set("questions_working", false)

    case "COMMUNITY_GET_ALL_QUESTIONS":
      return state
        .set("questions_working", true)
    case "COMMUNITY_GET_ALL_QUESTIONS_SUCCESS":
      return state
        .set("questions", action.data.data)
        .set("questions_working", false)
    case "COMMUNITY_GET_ALL_QUESTIONS_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_CREATE_QUESTION":
      return state
        .set("questions_working", true)
    case "COMMUNITY_CREATE_QUESTION_SUCCESS":
      var questions_create = state.get("questions");
      if(!questions_create?.items){
        questions_create = {items: []}
      }
      questions_create.items.unshift({...action.data.data})
      return state
        .set("questions", {...questions_create})
        .set("questions_working", false)
    case "COMMUNITY_CREATE_QUESTION_FAILED":
      return state
        .set("questions_working", false)

    case "COMMUNITY_GET_CATEGORIES":
      return state
        .set("categories_working", true)
    case "COMMUNITY_GET_CATEGORIES_SUCCESS":
      return state
        .set("categories", {...action.data.data})
        .set("categories_working", false)
    case "COMMUNITY_GET_CATEGORIES_FAILED":
      return state
        .set("categories_working", false)

		default:
			return state;
  }
}
