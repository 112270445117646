const communityActions = {
  COMMUNITY_GET_CATEGORIES: "COMMUNITY_GET_CATEGORIES",
  COMMUNITY_CREATE_QUESTION: "COMMUNITY_CREATE_QUESTION",
  COMMUNITY_GET_ALL_QUESTIONS: "COMMUNITY_GET_ALL_QUESTIONS",
  COMMUNITY_GET_QUESTION: "COMMUNITY_GET_QUESTION",
  COMMUNITY_QUESTION_UPVOTE: "COMMUNITY_QUESTION_UPVOTE",
  COMMUNITY_QUESTION_REPLY: "COMMUNITY_QUESTION_REPLY",
  COMMUNITY_QUESTION_PIN: "COMMUNITY_QUESTION_PIN",
  COMMUNITY_QUESTION_MOVE_TOPIC: "COMMUNITY_QUESTION_MOVE_TOPIC",

  ADMIN_UPDATE_QUESTION: "ADMIN_UPDATE_QUESTION",
  ADMIN_UPDATE_REPLY: "ADMIN_UPDATE_REPLY",
  ADMIN_DELETE_REPLY: "ADMIN_DELETE_REPLY",
  ADMIN_DELETE_QUESTION: "ADMIN_DELETE_QUESTION",

  ROADMAP_GET_CATEGORIES: "ROADMAP_GET_CATEGORIES",
  ROADMAP_CREATE: "ROADMAP_CREATE",
  ROADMAP_SEARCH: "ROADMAP_SEARCH",
  ROADMAP_UPLOAD_IMAGE: "ROADMAP_UPLOAD_IMAGE",
  ROADMAP_GET: "ROADMAP_GET",
  ROADMAP_CLEAR_IMAGE: "ROADMAP_CLEAR_IMAGE",
  ROADMAP_LOAD_IMAGE: "ROADMAP_LOAD_IMAGE",
  ROADMAP_UPVOTE: "ROADMAP_UPVOTE",
  ROADMAP_REPLY: "ROADMAP_REPLY",
  ROADMAP_GET_ROADMAPS: "ROADMAP_GET_ROADMAPS",

  ADMIN_ROADMAP_DELETE: "ADMIN_ROADMAP_DELETE",
  ADMIN_ROADMAP_UPDATE: "ADMIN_ROADMAP_UPDATE",
  ADMIN_ROADMAP_REPLY_DELETE: "ADMIN_ROADMAP_REPLY_DELETE",

  PRODUCT_UPDATE_GET_ALL: "PRODUCT_UPDATE_GET_ALL",

  productUpdateGetAll: (data) => ({
    type: communityActions.PRODUCT_UPDATE_GET_ALL,
    data,
  }),

  roadmapGetRoadmaps: (data) => ({
    type: communityActions.ROADMAP_GET_ROADMAPS,
    data,
  }),

  adminRoadmapReplyDelete: (data) => ({
    type: communityActions.ADMIN_ROADMAP_REPLY_DELETE,
    data,
  }),

  adminRoadmapUpdate: (data) => ({
    type: communityActions.ADMIN_ROADMAP_UPDATE,
    data,
  }),

  adminRoadmapDelete: (data) => ({
    type: communityActions.ADMIN_ROADMAP_DELETE,
    data,
  }),

  roadmapReply: (data) => ({
    type: communityActions.ROADMAP_REPLY,
    data,
  }),

  roadmapUpvote: (data) => ({
    type: communityActions.ROADMAP_UPVOTE,
    data,
  }),

  roadmapClearImage: (data) => ({
    type: communityActions.ROADMAP_CLEAR_IMAGE,
    data,
  }),

  roadmapLoadImage: (data) => ({
    type: communityActions.ROADMAP_LOAD_IMAGE,
    data,
  }),

  roadmapGet: (data) => ({
    type: communityActions.ROADMAP_GET,
    data,
  }),

  roadmapUploadImage: (data) => ({
    type: communityActions.ROADMAP_UPLOAD_IMAGE,
    data,
  }),

  roadmapSearch: (data) => ({
    type: communityActions.ROADMAP_SEARCH,
    data,
  }),

  roadmapCreate: (data) => ({
    type: communityActions.ROADMAP_CREATE,
    data,
  }),

  roadmapGetCategories: (data) => ({
    type: communityActions.ROADMAP_GET_CATEGORIES,
    data,
  }),

  adminDeleteQuestion: (data) => ({
    type: communityActions.ADMIN_DELETE_QUESTION,
    data,
  }),

  adminDeleteReply: (data) => ({
    type: communityActions.ADMIN_DELETE_REPLY,
    data,
  }),

  adminUpdateReply: (data) => ({
    type: communityActions.ADMIN_UPDATE_REPLY,
    data,
  }),

  adminUpdateQuestion: (data) => ({
    type: communityActions.ADMIN_UPDATE_QUESTION,
    data,
  }),

  //----------------------------------------------
  communityQuestionMoveTopic: (data) => ({
    type: communityActions.COMMUNITY_QUESTION_MOVE_TOPIC,
    data,
  }),

  communityQuestionPin: (data) => ({
    type: communityActions.COMMUNITY_QUESTION_PIN,
    data,
  }),

  communityQuestionReply: (data) => ({
    type: communityActions.COMMUNITY_QUESTION_REPLY,
    data,
  }),

  communityQuestionUpvote: (data) => ({
    type: communityActions.COMMUNITY_QUESTION_UPVOTE,
    data,
  }),

  communityGetQuestion: (data) => ({
    type: communityActions.COMMUNITY_GET_QUESTION,
    data,
  }),

  communityGetAllQuestions: (data) => ({
    type: communityActions.COMMUNITY_GET_ALL_QUESTIONS,
    data,
  }),

  communityCreateQuestion: (data) => ({
    type: communityActions.COMMUNITY_CREATE_QUESTION,
    data,
  }),

  communityGetCategories: (data) => ({
    type: communityActions.COMMUNITY_GET_CATEGORIES,
    data,
  }),

}

export default communityActions;
