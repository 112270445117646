import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import './style.css';
import FeatureTabs from "./FeatureTabs";
import {
  accDashboard, accMiscExp, accPnl, boxContent, printers, repricer, 
  repricerDashboard, repricerTemplate, research, scanList
} from "../../../assets/images";
import VideoPlayer from "../../../shared/components/VideoPlayer";

class Feature extends Component {
  render() {
    return (
      <section className="feature">
        <Container>
          <h2 className="section-title">Save time and make <br/>more profit!</h2>
          <Row className="feature-row">
            <Col className="feature-col-video">
                <VideoPlayer 
                  id="URtBPXhaZiQNrUdPIwLNEaSkqucd00xEnV01rcOMCb3d8"
                  title="Johanna R"
                  objectFit="contain"
                />
                <VideoPlayer 
                  id="GZZtGfcHO800HFGohDnYlrCuzQDYOCzW007C01R9c6FSoU"
                  title="Patrick S"
                />
            </Col>
            <Col className="feature-col-image">
              <h2 className="feature-title">PRODUCT LISTING</h2>
              <FeatureTabs
                data={{
                  tabs: ['Research', 'Scan and List', 'Thermal Printing', 'Box Content'],
                  contents: [
                    {img_url: research, description: 'Save yourself from that bad “purchase decision” and run your buy lists through our product research tool.'},
                    {img_url: scanList, description: 'Supercharge your pricing research by adding custom “intelligence” buttons as part of your listing workflow.'},
                    {img_url: printers, description: 'We work with Rollo, Dymo or Zebra thermal printers.'},
                    {img_url: boxContent, description: 'AccelerList offers several ways to get the box content process done for your shipments. One way is to use our internal box content manager.'}
                  ]
                }}
              />
            </Col>
            <Col sm={12}>
              <div className="feature-convert">
                <span>
                  Maximize profitability with our all-in-one tool that enables seamless listing, accurate pricing, and grading. Stay ahead with real-time cost tracking, profit visualization, and integrated calculators.
                </span>
                <a href="https://app.accelerlist.com/register" className="btn btn-primary btn-icon">
                  <span>Get Started</span>
                  <span className="icon icon-arrow-right icon-18"></span>
                </a>
              </div>
            </Col>
          </Row>
          <Row className="feature-row">
            <Col className="feature-col-image">
              <h2 className="feature-title">PRODUCT REPRICING</h2>
              <FeatureTabs
                data={{
                  tabs: ['Reprice and List in One Place', 'Beautiful dashboards', 'Precise Templates'],
                  contents: [
                    {img_url: repricer, description: 'No more shuffling between disparate applications on multiple browser tabs. Save time and money and reprice all in one application.'},
                    {img_url: repricerDashboard, description: 'Stop settling for boring visuals from other repricers that don’t care. Visualize your repricing efforts in a whole new way with Profit Reprice™.'},
                    {img_url: repricerTemplate, description: 'Get as detailed as you want or pull back and let us handle all the heavy lifting. Engineer the best templates and avoid the “race to the bottom.”'},
                  ]
                }}
              />
            </Col>
            <Col className="feature-col-video">
              <VideoPlayer 
                id="axNh7pL8shsITXfFPYq4AS5Wsl00OWQeNlh00IKn4aeV4"
                title="Jarrod A"
              />
              <VideoPlayer 
                id="ofFSTzJfC9AmXihZ25jcKConF619b3zgYZnV1g6GXQ4"
                title="Theresa M"
              />
            </Col>
            <Col sm={12}>
              <div className="feature-convert">
                <span>
                  Maximize profitability with our all-in-one tool that enables seamless listing, accurate pricing, and grading. Stay ahead with real-time cost tracking, profit visualization, and integrated calculators.
                </span>
                <a href="https://app.accelerlist.com/register" className="btn btn-primary btn-icon">
                  <span>Get Started</span>
                  <span className="icon icon-arrow-right icon-18"></span>
                </a>
              </div>
            </Col>
          </Row>
          <Row className="feature-row">
            <Col className="feature-col-video" id="video-accounting">
              <VideoPlayer 
                id="9gxB6VEMgSd7xshXkdo7QYu7Hu021fY6WHm01H7qQGQQM"
                title="Jennifer E"
              />
              <VideoPlayer 
                id="pHo5zwLN4sgVqEeGAJfeAyd02zbYabpNg9zRz9GeNeYg"
                title="Joshua N"
              />
            </Col>
            <Col className="feature-col-image">
              <h2 className="feature-title">ACCOUNTING</h2>
              <FeatureTabs
                data={{
                  tabs: ['Less confusion', 'Beautiful insights', 'Misc expenses and revenue?'],
                  contents: [
                    {img_url: accPnl, description: 'Make your CPA happy and simply import and print perfect P&L statements.'},
                    {img_url: accDashboard, description: 'Visualize your Amazon business in a new way with our stunning charts.'},
                    {img_url: accMiscExp, description: 'No problem! Enter them all with us and complete the accounting loop.'},
                  ]
                }}
              />
            </Col>
            <Col sm={12}>
              <div className="feature-convert">
                <span>
                  Maximize profitability with our all-in-one tool that enables seamless listing, accurate pricing, and grading. Stay ahead with real-time cost tracking, profit visualization, and integrated calculators.
                </span>
                <a href="https://app.accelerlist.com/register" className="btn btn-primary btn-icon">
                  <span>Get Started</span>
                  <span className="icon icon-arrow-right icon-18"></span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )

  }
}

export default Feature;